import DisplayItemHelper from '../../../util/DisplayItemHelper'
import DisplayItem from '../../../Components/DisplayItems/DisplayItem.jsx'

import { Button, ButtonGroup, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit'
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye'
import * as React from 'react'
import ContentDossierSend from '../../../Components/Pages/ContentDossier/ContentDossierToolbar/ContentDossierSend.jsx'
import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash'

interface ContentDossierIndexBodyProps {
    listItems: Array<IDisplayField>
    dossiers: Array<any>
    contents: Array<any>
    subscriptions: Array<any>
    actionBtns: Array<any>
    loadingContent: boolean
    mode: string
    kedo: any
    onSelect: any
    isSelected: any
    fetchDossierResults: any
    showEmail: boolean
    socketDossierIds: Array<any>
    extraColumns: any
    setInlineEdit: any
    tabsAllowed: any
    refreshing: any
    props: any
    setSocketDossierIds: any
    setQuickEdit: any
    setQuickShow: any
}

const ContentDossierIndexBody: React.FC<ContentDossierIndexBodyProps> = ({
    listItems,
    dossiers,
    contents,
    actionBtns,
    loadingContent,
    mode,
    kedo,
    onSelect,
    isSelected,
    fetchDossierResults,
    showEmail,
    socketDossierIds,
    subscriptions,
    tabsAllowed,
    extraColumns,
    refreshing,
    setInlineEdit,
    props,
    setSocketDossierIds,
    setQuickEdit,
    setQuickShow,
}): any => {
    const toTop = () => {
        window.scrollTo(0, 0)
    }

    const handleContent = (event, dossier): void => {
        event.stopPropagation()

        const trigger = event.target
        if (trigger.nodeName === 'INPUT') {
            return
        }
        if (
            trigger.className?.includes &&
            (trigger.className.includes('contentData') ||
                trigger.className.includes('displayItem') ||
                trigger.className.includes('list-group-item'))
        ) {
            if (props.embedded) {
                setInlineEdit(dossier.id)
            } else {
                if (
                    event.ctrlKey ||
                    (navigator.platform == 'MacIntel' && event.metaKey)
                ) {
                    window.open(`/contentdossier/${dossier.id}`, '_blank')
                    return
                }
                props.onClick(dossier)
                toTop()
            }
        }
    }

    /**
     * Check if the listItem as a specific custom field
     * @param listItem
     * @returns {boolean}
     */
    const isOtherDisplayItemField = (listItem): boolean => {
        if (
            !listItem.def_dossier_link &&
            !listItem.def_dossier_def_field &&
            listItem.settings &&
            listItem.settings.dossier_data
        ) {
            return true
        } else if (
            !listItem.def_dossier_link &&
            !listItem.def_dossier_def_field &&
            listItem.settings &&
            listItem.settings.block
        ) {
            return true
        } else if (
            DisplayItemHelper.isTypeOfField(
                listItem,
                DisplayItemHelper.FIELD_CUSTOMACTIONBTN_TYPE
            )
        ) {
            return true
        }

        return false
    }

    const getValue = (listItem, dossierData, dossier) => {
        const isOther = isOtherDisplayItemField(listItem)
        if (!dossierData && true !== isOther) {
            return ''
        }

        const isListItem = DisplayItemHelper.isTypeOfField(
            listItem,
            DisplayItemHelper.FIELD_LIST_TYPE
        )

        let singleDossierData = dossierData.find(
            (data) => data.content.id === listItem.id
        ) as any

        if (singleDossierData?.content?.skip === true) {
            return ''
        }

        if (singleDossierData && singleDossierData.content) {
            singleDossierData = singleDossierData.content.content
        }

        if (isListItem === true && listItem.settings?.fast_switch === true) {
            return (
                <DisplayItem
                    kedo={kedo}
                    changeValue={() => {
                        //TODO
                    }}
                    content={singleDossierData}
                    dossier={dossier}
                    errors={null}
                    item={listItem}
                    key={listItem.id}
                    mode={'list'}
                />
            )
        }

        if (true === isOther) {
            //Dossier data list field
        } else if (!singleDossierData || singleDossierData.length <= 0) {
            return ''
        }
        return (
            <DisplayItem
                kedo={kedo}
                changeValue={() => {
                    //TODO
                }}
                fetchItems={fetchDossierResults}
                content={singleDossierData}
                dossier={dossier}
                errors={null}
                item={listItem}
                key={listItem.id}
                mode={DisplayItemHelper.LIST_VIEW}
            />
        )
    }

    const renderContentRow = (dossierData, dossier) => {
        const doTransition =
            (mode === 'embeddedList' || mode === 'block') &&
            socketDossierIds.find((item) => item === dossier.id)
                ? true
                : false
        const style = {
            borderLeft: doTransition ? '8px solid #bddbf0' : '',
            fontWeight: doTransition ? 'bold' : '',
        }

        let tabAllowed = true

        if (tabsAllowed.length > 0) {
            const findTab = tabsAllowed.find(
                (tab: { id: any }) => tab.id === dossier.id
            )

            if (findTab) {
                tabAllowed = findTab.tabs_allowed === true
            }
        }
        const cursorClass = tabAllowed ? 'pointer' : 'not-allowed'
        if (!tabAllowed) {
            style['cursor'] = 'not-allowed'
        }

        return (
            <>
                <tr
                    key={dossier.id}
                    style={style}
                    className={`contentRow ${
                        dossier.archived ? 'archivedDossier' : ''
                    } `}
                >
                    <td className={'contentData'} style={{ width: '3%' }}>
                        {tabAllowed ? (
                            <Form.Check
                                disabled={!tabAllowed}
                                checked={isSelected(dossier)}
                                inline
                                label={''}
                                onClick={() => onSelect(event, dossier)}
                                style={{ marginRight: '0px' }}
                                type={'checkbox'}
                                value={dossier.id}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                    </td>
                    {showEmail === true ? (
                        <td>
                            <ContentDossierSend kedo={kedo} dossier={dossier} />
                        </td>
                    ) : null}
                    <td
                        className={'contentData'}
                        style={{ textAlign: 'center', width: '3%' }}
                    >
                        {subscriptions.find(
                            (item) => item.content_dossier_id === dossier.id
                        ) ? (
                            <FontAwesomeIcon icon={faBell} />
                        ) : null}
                    </td>
                    {listItems.map((listItem) => (
                        <td
                            style={{ cursor: `${cursorClass}` }}
                            onClick={(event) => {
                                if (!tabAllowed) {
                                    return
                                }
                                if (
                                    DisplayItemHelper.isTypeOfField(
                                        listItem,
                                        DisplayItemHelper.FIELD_CUSTOMACTIONBTN_TYPE
                                    ) === false
                                ) {
                                    setQuickShow(true)
                                    handleContent(event, dossier)
                                }
                            }}
                            className={'contentData'}
                            key={listItem.id}
                        >
                            {getValue(listItem, dossierData, dossier)}
                        </td>
                    ))}
                    {tabAllowed && actionBtns.length > 0 ? (
                        <td className={'contentData'}>
                            <ButtonGroup
                                className={'displayItem'}
                                vertical
                                aria-label="Actions"
                            >
                                {actionBtns.map((actionBtn) =>
                                    getValue(actionBtn, dossierData, dossier)
                                )}
                            </ButtonGroup>
                        </td>
                    ) : null}
                    <td
                        className={
                            mode === 'embeddedList'
                                ? 'tc-inline-edit-embedded'
                                : 'tc-inline-edit'
                        }
                    >
                        {tabAllowed ? (
                            <Button
                                disabled={!tabAllowed}
                                size={'sm'}
                                className={'dossier-inline-edit'}
                                onClick={() => {
                                    const socketDossiers =
                                        socketDossierIds.filter(
                                            (item) => item !== dossier.id
                                        )

                                    setQuickEdit(false)
                                    setQuickShow(true)
                                    setInlineEdit(dossier.id)
                                    setSocketDossierIds(socketDossiers)
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        ) : null}
                    </td>
                    <td
                        className={
                            mode === 'embeddedList'
                                ? 'tc-inline-edit-embedded'
                                : 'tc-inline-edit'
                        }
                    >
                        {tabAllowed ? (
                            <Button
                                disabled={!tabAllowed}
                                size={'sm'}
                                className={'float-right dossier-inline-edit'}
                                onClick={() => {
                                    const socketDossiers =
                                        socketDossierIds.filter(
                                            (item) => item !== dossier.id
                                        )

                                    setQuickEdit(true)
                                    setQuickShow(false)
                                    setInlineEdit(dossier.id)
                                    setSocketDossierIds(socketDossiers)
                                }}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        ) : null}
                    </td>
                </tr>
            </>
        )
    }

    if (!dossiers || dossiers.length <= 0) {
        return <tbody />
    }

    if (loadingContent) {
        return (
            <tbody>
                <tr>
                    <td
                        className={'text-center'}
                        colSpan={listItems.length + extraColumns}
                    >
                        <LoadingDefault />
                    </td>
                </tr>
            </tbody>
        )
    }

    return (
        <tbody>
            <tr>
                <td
                    className={'seperator-loader'}
                    colSpan={listItems.length + extraColumns + 1}
                >
                    {refreshing ? (
                        <section className={'seperator-wrapper'}>
                            <div className={'seperator gradient'}></div>
                        </section>
                    ) : null}
                </td>
            </tr>
            {dossiers.map((dossier) =>
                renderContentRow(
                    contents.filter((dataItem) => dataItem.id === dossier.id),
                    dossier
                )
            )}
        </tbody>
    )
}
export default ContentDossierIndexBody
